<template>
  <div>
    <v-carousel v-model="model" height="700">
      <v-carousel-item
          v-for="(photo, i) in photoProps"
          :key="photo"
          @click="openLightbox(photo)"
      >
        <img
            :src="'/api/attachments/'+device.metadata.instanceID.replace('uuid:', '')+'/'+device.metadata[photo].assetFilename"
            style="width: 100%; height: 100%; object-fit: contain;"
        />
      </v-carousel-item>
    </v-carousel>

    <div v-if="lightboxImage" class="lightbox" @click="closeLightbox">
      <img :src="lightboxImage" class="lightbox-img" />
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View Device'
  },
  data() {
    return {
      lightboxImage: null,
      device: {
      },
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
    }
  },
  computed: {
  },
  methods: {
    getDevice() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('devices/get', this.$route.params.id).then((result) => {
              this.device = result;
            }
        );
      }
    },
    openLightbox(photo) {
      this.lightboxImage = '/api/attachments/' + this.device.metadata.instanceID.replace('uuid:', '') + '/' + this.device.metadata[photo].assetFilename;
    },
    closeLightbox() {
      this.lightboxImage = null;
    }
  },
  mounted() {
    this.getDevice();
  }
}
</script>
<style>
    .lightbox {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }

    .lightbox-img {
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
    }
  </style>
